.Home .lander {
	padding: 80px 0;
	text-align: center;
}

.Home .lander div {
	padding-top: 20px;
}
.Home .lander div a:first-child {
	margin-right: 20px;
}

.Home .lander h1 {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
}

.Home .lander p {
	color: #999;
}

.Home .sessions h4 {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
	overflow: hidden;
	line-height: 1.5;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.Home .sessions p {
	color: #666;
}